// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import Link from "next/link";
import * as Theme$SimonKjellberg from "../Theme.bs.js";
import * as Utils$SimonKjellberg from "../Utils.bs.js";

var wrapper = Curry._1(Css.style, {
      hd: Css.color(Theme$SimonKjellberg.Header.color),
      tl: {
        hd: Css.backgroundColor(Theme$SimonKjellberg.Header.backgroundColor),
        tl: {
          hd: Css.marginBottom(Css.rem(1.45)),
          tl: /* [] */0
        }
      }
    });

var container = Curry._1(Css.style, {
      hd: Css.margin2("zero", "auto"),
      tl: {
        hd: Css.maxWidth(Css.rem(35.0)),
        tl: {
          hd: Css.padding2(Css.rem(1.45), Css.rem(1.0875)),
          tl: {
            hd: Css.unsafe("@supports(padding: max(0px)) and (padding: env(safe-area-inset-top))", Curry._1(Css.style, {
                      hd: Css.unsafe("paddingTop", "max(env(safe-area-inset-top), 1.45rem)"),
                      tl: {
                        hd: Css.unsafe("paddingLeft", "max(env(safe-area-inset-left), 1.0875rem)"),
                        tl: {
                          hd: Css.unsafe("paddingRight", "max(env(safe-area-inset-right), 1.0875rem)"),
                          tl: /* [] */0
                        }
                      }
                    })),
            tl: /* [] */0
          }
        }
      }
    });

var title = Curry._1(Css.style, {
      hd: Css.fontSize(Css.rem(1.0)),
      tl: {
        hd: Css.fontWeight("bold"),
        tl: {
          hd: Css.margin("zero"),
          tl: {
            hd: Css.textTransform("lowercase"),
            tl: /* [] */0
          }
        }
      }
    });

var link = Curry._1(Css.style, {
      hd: Css.color(Theme$SimonKjellberg.Header.color),
      tl: {
        hd: Css.textDecoration("none"),
        tl: /* [] */0
      }
    });

var Styles = {
  wrapper: wrapper,
  container: container,
  title: title,
  link: link
};

function Header(Props) {
  var siteTitle = Props.siteTitle;
  return React.createElement("header", {
              className: wrapper
            }, React.createElement("div", {
                  className: container
                }, React.createElement("h1", {
                      className: title
                    }, React.createElement(Link, {
                          href: "/",
                          passHref: true,
                          children: React.createElement("a", {
                                className: link
                              }, Utils$SimonKjellberg.str("#!/" + siteTitle))
                        }))));
}

var make = Header;

var $$default = Header;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
